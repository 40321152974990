





































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN"
    })
  }
});
